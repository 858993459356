<template>
  <div style="margin: 10px">
    <b-row cols="1" cols-md="2">
      <!-- Kiosztható kérdőívek -->
      <div style="padding: 10px">
        <b-row style="margin: 0px 0px 30px">
          <fp-input
            style="margin: 0px 5px"
            :label="$t('base.basic.filter')"
            @change="filterList"
            v-model="filter"
            class="eh-fix-filter-input-width"
          />
          <fp-date-range-button
            :label="
              $t('healthProfessional.questionnaires.assignableTab.createDate')
            "
            v-model="createdDateFilter"
            @change="filterList"
          />
        </b-row>
        <fp-table-list
          style="margin-top: -20px"
          refId="questionnaire-list-for-patients"
          id="questionnaire-list-for-patients"
          class="questionnaires-to-patient-table"
          :hasPagination="true"
          :items="filteredQuestionnaireList"
          :fields="questionnaireListFields"
          @row-selected="questionnaireSelected"
          selectMode="single"
          :selectable="true"
        >
          <template #Title="row">
            {{ getTextByLanguage(row.item.Title, row.item.Languages) }}
          </template>
          <template #Description="row">
            {{ getTextByLanguage(row.item.Description, row.item.Languages) }}
          </template>
          <template #Added="row">
            {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
          </template>
          <template #Languages="row">
            <span v-for="lang in row.item.Languages" :key="lang">
              <img
                width="25px"
                style="margin: 2px 5px"
                :src="require('@/assets' + $enums.Languages[lang].flag)"
              />
            </span>
          </template>
          <template #Buttons="row">
            <b-icon
              class="h4"
              v-b-tooltip.hover="$t('base.basic.view')"
              style="cursor: pointer"
              icon="eye-fill"
              @click="$set(row.item, 'ShowFormViewer', true)"
            />
            <fp-form-modal size="xl" v-model="row.item.ShowFormViewer">
              <template #content>
                <fp-form-schema-builder
                  :formSchemaId="row.item.FormSchemaId"
                  :disabled="true"
                />
              </template>
            </fp-form-modal>
          </template>
        </fp-table-list>
      </div>
      <!-- Betegek listája -->
      <div style="padding: 10px">
        <div>
          <fp-input
            class="eh-fix-filter-input-width"
            style="margin-top: 0px"
            :label="$t('base.basic.filter')"
            @change="filterPatientList"
          />
        </div>
        <!-- <GroupRowMultiselectTable
          style="
            margin-top: 10px;
            border: solid 3px #627183;
            border-radius: 10px;
            max-height: 500px;
            overflow: auto;
          "
          :emptyMessage="
            $t('healthProfessional.questionnaires.assignableTab.noPatient')
          "
          @selectedItems="selectedPatientList = $event"
          :items="patienListGroupedByTags"
          :fields="patientListFieldds"
        /> -->
        <fp-table-list
          :items="patientList"
          :fields="patientListFieldds"
          :selectable="true"
          @row-selected="selectPatients"
        >
        <template #PatientDateOfBirth="row">
          {{$convertDateToString(row.item.PatientDateOfBirth,'YYYY-MM-DD')}}
        </template>
        </fp-table-list>
      </div>
    </b-row>
    <b-check v-model="asTask">{{
      $t("healthProfessional.questionnaires.assignableTab.newFormByTask")
    }}</b-check>
    <b-check v-if="asTask" v-model="hardDeadline">
      {{
        $t(
          "healthProfessional.questionnaires.assignableTab.newFormHardDeadline"
        )
      }}
    </b-check>
    <b-row
      style="margin: 0px 0px 20px"
      align-h="between"
      align-v="end"
      cols="1"
      cols-md="2"
    >
      <div style="display: flex">
        <div v-if="asTask" style="width: 400px">
          <fp-date-time-picker
            :label="
              $t('healthProfessional.questionnaires.assignableTab.newSendDate')
            "
            v-model="startDate"
            :required="true"
          />
        </div>
        <div v-if="asTask" style="width: 300px; padding-left: 20px">
          <fp-input
            :label="
              $t('healthProfessional.questionnaires.assignableTab.newdeadline')
            "
            :placeholder="
              $t(
                'healthProfessional.questionnaires.assignableTab.newdeadlineUnit'
              )
            "
            :description="
              $t('healthProfessional.questionnaires.assignableTab.fillTime')
            "
            v-model="deadlineDate"
            :required="true"
          />
        </div>
      </div>
      <div style="max-width: 300px; padding: 10px">
        <b-button
          style="height: fit-content"
          :disabled="
            selectedQuestionnaire.length == 0 ||
            selectedPatientList.length == 0
          "
          @click="renderQuestionnaireToPatient"
          >{{
            this.$t(
              "healthProfessional.questionnaires.assignableTab.giveQuestionnaries",
              {
                count: selectedPatientList.length,
              }
            )
          }}</b-button
        >
      </div>
    </b-row>
  </div>
</template>
<script>
import moment from "moment";
import { ProjectLogic } from "../../../Logic/Backend/project.js";
import { HelperMethods } from "@/logic/ui/helpers";
import { FormLogic } from "../../../Logic/Backend/form";
//import GroupRowMultiselectTable from "@/components/table/GroupRowMultiselectTable";

export default {
  name: "QuestionnaireAssignableList",
  /* components: {
    GroupRowMultiselectTable,
  }, */
  data() {
    return {
      patienListGroupedByTags: [],
      patientList: [],
      patientListFieldds: [
        {
          key: "Ssn",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.patientSsn"
          ),
        },
        {
          key: "Name",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.patientName"
          ),
        },
        {
          key: "PatientDateOfBirth",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.patientBirthday"
          ),
          formatter: (value) => {
            return this.$convertDateToString(value, "YYYY-MM-DD");
          },
        },
        /* {
          key: "Remark",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.patientNote"
          ),
        }, */
      ],
      questionnaireList: [],
      questionnaireListFields: [
        {
          key: "Title",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.formTitle"
          ),
        },
        {
          key: "Description",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.formDescription"
          ),
        },
        {
          key: "Languages",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.supportedLanguages"
          ),
        },
        {
          key: "Added",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.formCreatedDate"
          ),
        },
        { key: "Buttons", label: "" },
      ],
      selectedPatientList: [],
      selectedQuestionnaire: [],
      filteredQuestionnaireList: [],
      filteredPatientList: [],
      deadlineDate: null,
      startDate: moment().format(),
      filter: null,
      createdDateFilter: null,
      asTask: false,
      hardDeadline: false,
    };
  },
  props: {
    projectItems: Array,
  },
  methods: {
    selectPatients(row) {
      this.selectedPatientList = row;
    },
    getTextByLanguage(textObject, languages) {
      if (typeof textObject == "object") {
        const lang = languages ? languages : Object.keys(textObject);
        if (lang.includes(this.$i18n.locale)) {
          return textObject[this.$i18n.locale];
        } else if (lang.includes("en")) {
          return textObject.en;
        } else {
          return textObject[lang[0]];
        }
      } else {
        return textObject;
      }
    },
    filterList() {
      this.filteredQuestionnaireList = this.questionnaireList;
      if (this.filter) {
        this.filteredQuestionnaireList = this.$filterList(
          this.filter,
          this.questionnaireList,
          ["Title", "Description"]
        );
      }
      if (this.createdDateFilter) {
        this.filteredQuestionnaireList = this.filteredQuestionnaireList.filter(
          (task) => {
            if (this.createdDateFilter.from && this.createdDateFilter.to) {
              return moment(task.CreatedDate).isBetween(
                this.createdDateFilter.from,
                this.createdDateFilter.to,
                undefined,
                "[]"
              );
            } else if (this.createdDateFilter.from) {
              return moment(task.CreatedDate).isSameOrAfter(
                moment(this.createdDateFilter.from)
              );
            } else if (this.createdDateFilter.to) {
              return moment(task.CreatedDate).isSameOrBefore(
                moment(this.createdDateFilter.to)
              );
            }
            return true;
          }
        );
      }
    },
    //kérdőív kijelölt sorok tárolása
    questionnaireSelected(row) {
      this.selectedQuestionnaire = row;
    },
    //beteglista szűrése
    filterPatientList(input) {
      //megadott input, és változó kulcsok alapján szűrt lista tárolása
      this.filteredPatientList = this.$filterList(input, this.patientList, [
        "Ssn",
        "Name",
        "PatientDateOfBirth",
        "Remark",
      ]);

      //szűrt lista cimke alapján való kategorizálása
      this.generateGroupedPatientList();
    },
    //kérdőívek kiosztása a betegeknek kérés
    async renderQuestionnaireToPatient() {
      //body felépítése
      const temp = {
        FormSchemaId: this.selectedQuestionnaire[0].FormSchemaId,
        FormElements: [],
        TaskNeeded: this.asTask,
        startTime: this.asTask ? this.startDate : null,
        Deadline: this.asTask ? this.deadlineDate : null,
        HardDeadline: this.asTask ? this.hardDeadline : false,
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      };
      //a beteglistában megjelölt betegek idjainak kiszedése a bodyba
      for (const p of this.selectedPatientList) {
        /* //a beteg Active jelölője true?
        if (p.isActive) { */
          //igen, body beteglistába beillesztjük a kiválasztott beteg idját
          temp.FormElements.push({
            SubjectId: p.UserId,
            AssigneeId: p.UserId,
            EvaluatorId: this.$loggedUser.UserId,
          });
       /*  } */
      }
      //kiosztás kérés indítása
      const renderQuestionnaireToPatientResponse =
        await FormLogic.createFormInstance(temp);
      //sikeres kérés?
      if (
        !renderQuestionnaireToPatientResponse.Code ||
        renderQuestionnaireToPatientResponse.Code == 0
      ) {
        //sikeres üzenet
        this.$bvToast.toast(
          this.$t("requestResponse.questionnaire.successAssignedMessage"),
          {
            title: this.$t(
              "requestResponse.questionnaire.successAssignedTitle"
            ),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(renderQuestionnaireToPatientResponse.Message, {
          title: this.$t("requestResponse.questionnaire.errorAssignedMessage"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //beteglista kérés
    async getPatientList() {
      //kérés indítása
      const patientListResult = await ProjectLogic.getProjectSubordinates(
        this.$loggedUser.SelectedProject.ProjectId
      );
      //sikeres kérés?
      if (!patientListResult.Code) {
        //beteg lista frissítés
        this.patientList = this.filteredPatientList = patientListResult;
        //beteg lista csoportosítás cimkék alapján
        this.generateGroupedPatientList();
      } else {
        this.$bvToast.toast(patientListResult.Message, {
          title: this.$t("requestResponse.project.errorGetProjectSubordinates"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //beteglista csoportosítása cimkék alapján
    generateGroupedPatientList() {
      //betegek lista csoportodítása, megadott metódus alapján, arrayKey, cimke nélküli elemek csoport neve
      const patientMapGroupedByTags = HelperMethods.groupByArrayItems(
        this.filteredPatientList,
        (x) => {
          return x.Tags.map((tag) => {
            return { Name: this.getTextByLanguage(tag.Name) };
          });
        },
        "Name",
        this.$t("healthProfessional.questionnaires.assignableTab.noTag")
      );
      //a csoportosított beteg lista tároló urítése
      this.patienListGroupedByTags = [];
      //kapott csoportosított map tárolása arrayként a kiürített tárolóba
      patientMapGroupedByTags.forEach((value, key) =>
        this.patienListGroupedByTags.push({ key: key, value: value })
      );
    },
    //kérdőív lista kérés
    async getQuestionnaireList() {
      if (this.projectItems) {
        const questionnaireListResult = await FormLogic.getFormSchemaHeader({
          FormCategory: "Questionnaire",
          Status: "Active",
        });
        //sikeres kérés?
        if (!questionnaireListResult.Code) {
          //kérdőív lista frissítés
          const schema = questionnaireListResult;
          this.questionnaireList = this.filteredQuestionnaireList =
            schema.filter((s) =>
              this.projectItems.some(
                (item) =>
                  item.EntityTypeName ==
                    this.$enums.ProjectItemsEntityTypeName.FormSchema &&
                  s.FormSchemaId == item.EntityId
              )
            );
        } else {
          this.$bvToast.toast(questionnaireListResult.Message, {
            title: this.$t("requestResponse.basic.errorGetNamedList", {
              name: this.$t(
                "healthProfessional.questionnaires.assignableTab.errorName"
              ),
            }),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //beteglista betöltése
    await this.getPatientList();
    //kérdőívek betöltése
    await this.getQuestionnaireList();
  },
};
</script>
<style scoped>
.questionnaires-to-patient-table {
  background: white;
}
.questionnaires-to-patient-table-box {
  padding: 10px;
}
.questionnaires-to-patient-table-title {
  font-size: 28px;
  text-align: center;
}
.date-picker-calendar-quest {
  position: absolute;
  top: 40px;
  right: -100px;
  background: white;
  border: solid 3px #627183;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
}
</style>
